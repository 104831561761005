import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  {
    toolBarWrapper: {
      backgroundColor: '#0e0c38',
      color: 'white',
      height: '100%',
    },
    toolBar: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },

    logoHeader: {
      textDecoration: 'none',
      color: '#ffffff',
      fontSize: '2.625rem',
      fontWeight: 600,
    },

    profileContainer: {
      marginLeft: 'auto',
      textAlign: 'right',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '100%',
    },

    imgContainer: {
      height: '40px',
      width: '40px',
      paddingRight: '30%',
    },

    img: {
      height: '40px',
      width: '40px',
      borderRadius: '50%',
    },

    profil: {
      textTransform: 'capitalize',
    },

    menu: {
      color: 'white !important',
      borderRadius: '32px !important',
      borderColor: 'white !important',
      padding: '20px !important',
      height: '50px',
      width: '90%',
    },

    active:{
     color:'#F48C06 !important',
    },

    link: {
      color: 'white',
      alignItems: 'center',
      alignContent: 'center',
      display: 'flex',
      fontWeight: 600,
      padding: '12px',
      textDecoration: 'none',
      "&:hover": {
        color: "#E85D04",
      }
    },

    icon: {
      paddingRight: '3px',
    },
  },
  { name: 'header' },
);

export default useStyles;

import { createContext, useContext } from 'react';

export interface IContextHairunQuiz {
  profile: any;
  checkedPermission: (featureNameValue: string, accessKey: string) => boolean;
}

export const ContextHairunQuiz = createContext<IContextHairunQuiz>({
  profile: {},
  checkedPermission: () => true || false,
});

export const useContextHairunQuiz = () => useContext(ContextHairunQuiz);

import { ApolloClient, InMemoryCache, DefaultOptions, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  },
  query: {
    fetchPolicy: 'cache-first',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
};

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_URL_API,
});

const authLink = setContext((_, { headers }) => {
  const token = JSON.parse(localStorage.getItem('token') ?? '');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

export const client = new ApolloClient({
  // uri: 'http://localhost:8080/graphql',
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions,
});

import React, { FC } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Stack } from '@mui/material';

export const Loading: FC = () => (
  <Stack
    sx={{ marginTop: 'calc(100vh - 32rem)', top: 0 }}
    alignItems="center"
    justifyContent="center"
  >
    <CircularProgress />
  </Stack>
);

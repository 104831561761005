import { createSlice } from '@reduxjs/toolkit';

const intitialState: { start: boolean; count: number; timeout: boolean; id: string } = {
  start: false,
  count: 0,
  timeout: false,
  id:"",
};

const countDownSlice = createSlice({
  name: 'getCountDownSlice',
  initialState: {
    getCountDown: intitialState,
  },
  reducers: {
    setCountDown: (state, action) => {
      if (action.payload.type === 'countdown') {
        const newTime = state.getCountDown.count - 1;
        const result = newTime >= 0 ? newTime : 0;
        state.getCountDown = { 
          start: result !== 0, 
          count: result, 
          timeout: result === 0,
          id: state.getCountDown.id
        };
      } else {
        state.getCountDown = action.payload;
      }
    },
  },
});

export const { setCountDown } = countDownSlice.actions;

export default countDownSlice.reducer;

import React from 'react';
import { Box, Grid, Link, Typography } from '@mui/material';
import Logo from '@/assets/images/logo.png';
import useStyles from './styles';

const NotFound = () => {
  const classes = useStyles();
  return (
    <Box className={classes.notFound}>
      <Grid container>
        <Grid item xs={12}>
          <img src={Logo} alt="Logo" />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h3">404</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            Désolé, la page est introuvable.
            <Link href="/" color="secondary" underline="hover">
              Revenir à la page d&apos; acceuil
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NotFound;

import React, { lazy } from 'react';
import {
  HOMEPAGE,
  CONTACT,
  REGISTER,
  LOGIN,
  QUIZ,
  QUESTIONNAIRE,
  USERS,
  SEANCE,
  QUIZ_USER_GUEST,
  DASHBOARD,
  PERMISSION,
  RESET_PASSWORD,
} from './ConstantRoute';

interface RouteInterface {
  key: string;
  path: string;
  isPrivate: boolean;
  // eslint-disable-next-line no-undef
  component: React.LazyExoticComponent<() => JSX.Element>;
}
const Home = lazy(() => import('@/Container/Home/Home'));
const Contact = lazy(() => import('@/Container/Contact/Contact'));
const Register = lazy(() => import('@/Container/Register/Register'));
const Login = lazy(() => import('@/Container/Login/Login'));
const Quiz = lazy(() => import('@/Container/Quiz/Quiz'));
const Questionnaire = lazy(() => import('@/Container/Questionnaire/Questionnaire'));
const Users = lazy(() => import('@/Container/Users/Users'));
const Seance = lazy(() => import('@/Container/Seance'));
const Dashboard = lazy(() => import('@/Container/Dashboard'));
const Permission = lazy(() => import('@/Container/Permission'));
const ResetPassword = lazy(() => import('@/Container/ResetPassword'));
const RessetPasswordToken = lazy(() => import('@/Container/ResetPassword/FormResetPass/FormResetPass'));

const routes: RouteInterface[] = [
  {
    key: 'Home',
    path: HOMEPAGE,
    component: Home,
    isPrivate: true,
  },
  {
    key: 'Contact',
    path: CONTACT,
    component: Contact,
    isPrivate: true,
  },
  {
    key: 'Register',
    path: REGISTER,
    component: Register,
    isPrivate: false,
  },
  {
    key: 'Login',
    path: LOGIN,
    component: Login,
    isPrivate: false,
  },
  {
    key: 'Quiz',
    path: QUIZ,
    component: Quiz,
    isPrivate: true,
  },
  {
    key: 'QuizUserGuest',
    path: QUIZ_USER_GUEST,
    component: Quiz,
    isPrivate: true,
  },
  {
    key: 'Questionnaire',
    path: QUESTIONNAIRE,
    component: Questionnaire,
    isPrivate: true,
  },
  {
    key: 'Users',
    path: USERS,
    component: Users,
    isPrivate: true,
  },
  {
    key: 'Seance',
    path: SEANCE,
    component: Seance,
    isPrivate: true,
  },
  {
    key: 'Dashboard',
    path: DASHBOARD,
    component: Dashboard,
    isPrivate: true,
  },
  {
    key: 'Permission',
    path: PERMISSION,
    component: Permission,
    isPrivate: true,
  },
  {
    key: 'RessetPassword',
    path: RESET_PASSWORD,
    component: ResetPassword,
    isPrivate: false,
  },
  {
    key: 'RessetPasswordToken',
    path: `${RESET_PASSWORD}/:token`,
    component: RessetPasswordToken,
    isPrivate: false,
  },
];

export default routes;

import React, { FC } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import Home from '@mui/icons-material/Home';
import PeopleIcon from '@mui/icons-material/People';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import LogoutIcon from '@mui/icons-material/Logout';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { useContextHairunQuiz } from '@/Hooks/Context/ContextHairunQuiz';
import { DASHBOARD, HOMEPAGE, LOGIN, QUESTIONNAIRE, SEANCE, USERS } from '@/Routes/ConstantRoute';
import useStyles from './styles';

interface PropsComponent {
  pathname: string;
  logout: () => void;
}

const HeaderForWeb: FC<PropsComponent> = ({ pathname, logout }) => {
  const { checkedPermission } = useContextHairunQuiz();
  const classes = useStyles();

  return (
    <Box display="flex" flexGrow="1" justifyContent="right">
      <Link
        to={HOMEPAGE}
        className={clsx(classes.link, {
          [classes.active]: pathname === HOMEPAGE,
        })}
      >
        <Home className={classes.icon} /> Home
      </Link>
      {/* TO DO */}
      {/* <Link
        to={DASHBOARD}
        className={clsx(classes.link, {
          [classes.active]: pathname === DASHBOARD,
        })}
      >
        <DashboardIcon className={classes.icon} /> Dashboard
      </Link> */}
      {checkedPermission('Questionnaire', 'isUpdate') && (
        <Link
          to={QUESTIONNAIRE}
          className={clsx(classes.link, {
            [classes.active]: pathname === QUESTIONNAIRE,
          })}
        >
          <QuestionMarkIcon className={classes.icon} /> Questionnaires
        </Link>
      )}
      {checkedPermission('Session', 'isRead') && (
        <Link
          to={SEANCE}
          className={clsx(classes.link, {
            [classes.active]: pathname === SEANCE,
          })}
        >
          <AccessTimeFilledIcon className={classes.icon} /> Session
        </Link>
      )}
      {checkedPermission('User', 'isRead') && (
        <Link
          to={USERS}
          className={clsx(classes.link, {
            [classes.active]: pathname === USERS,
          })}
        >
          <PeopleIcon className={classes.icon} /> Gestion utilisateurs
        </Link>
      )}
      <Link
        to={LOGIN}
        onClick={logout}
        className={clsx(classes.link, {
          [classes.active]: pathname === LOGIN,
        })}
      >
        <LogoutIcon className={classes.icon} /> Déconnexion
      </Link>
    </Box>
  );
};

export default HeaderForWeb;

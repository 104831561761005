import React from 'react';
import { Routes, Route } from 'react-router-dom';
import routes from '@Routes/routes';
import Protected from '@Routes/ProtectedRoute';
import clsx from 'clsx';
import { CssBaseline, ThemeProvider } from '@mui/material';
import theme from './Theme';
import useStyles from './AppStyles';
import NotFound from './Components/Layout/NotFound';

function App() {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className={clsx('App', classes.app)}>
        <Routes>
          {routes?.map(item => {
            const { key, path, isPrivate, component: Component } = item;
            return (
              <Route
                key={key}
                path={path}
                element={
                  isPrivate ? (
                    <Protected>
                      <Component />
                    </Protected>
                  ) : (
                    <Component />
                  )
                }
              />
            );
          })}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </ThemeProvider>
  );
}

export default App;

import { gql } from '@apollo/client';

export const GET_PROFILE = gql`
  query Profile {
    profile {
      id
      username
      email
      matricule
      firstName
      userRole {
        label
        value
        roleAccess {
          featureName
          isRead
          isCreate
          isUpdate
          isDelete
        }
      }
    }
  }
`;

export const GET_USERS = gql`
  query GetUsers {
    getUsers {
      id
      matricule
      firstName
      username
      email
      userRoleId
      userRole {
        id
        label
      }
      userMatrix {
        id
      }
    }
  }
`;

export const GET_USERS_WITH_MATRIX = gql`
  query GetUsersMatrix {
      getUsers {
        id
        matricule
        firstName
        username
        userMatrix{
          id
          technology
          note
          type
        }
      }
    }
`

export const GET_USER_ROLES = gql`
  query GetUserRoles {
    getUserRoles {
      id
      label
      value
      roleAccess {
        id
        label
        featureName
        isRead
        isCreate
        isUpdate
        isDelete
        userRoleId
      }
    }
  }
`;

export const CREATE_USER_ROLE_WITH_ACCESS = gql`
  mutation CreateUserRoleWithAccess(
    $label: String!
    $value: String!
    $roleAccess: [CreateRoleAccessInput!]!
  ) {
    createUserRoleWithAccess(
      createUserRoleWithAccessInput: { label: $label, value: $value, roleAccess: $roleAccess }
    ) {
      id
      label
      value
      roleAccess {
        id
        label
        featureName
        isRead
        isCreate
        isUpdate
        isDelete
        userRoleId
      }
    }
  }
`;

export const CREATE_ACCESS_ROLE = gql`
  mutation CreateRoleAccess(
    $label: String!
    $featureName: String!
    $isRead: Boolean
    $isCreate: Boolean
    $isUpdate: Boolean
    $isDelete: Boolean
    $userRoleId: String!
  ) {
    createRoleAccess(
      createRoleAccessInput: {
        label: $label
        featureName: $featureName
        isRead: $isRead
        isCreate: $isCreate
        isUpdate: $isUpdate
        isDelete: $isDelete
        userRoleId: $userRoleId
      }
    ) {
      id
      label
      featureName
      isRead
      isCreate
      isUpdate
      isDelete
      userRoleId
    }
  }
`;

export const REMOVE_ACCESS_ROLE = gql`
  mutation RemoveRoleAccess($id: String!) {
    removeRoleAccess(id: $id) {
      id
      label
    }
  }
`;

export const UPDATE_ACCESS_ROLE = gql`
  mutation UpdateRoleAccess(
    $id: String!
    $label: String!
    $featureName: String!
    $isRead: Boolean
    $isCreate: Boolean
    $isUpdate: Boolean
    $isDelete: Boolean
    $userRoleId: String!
  ) {
    updateRoleAccess(
      updateRoleAccessInput: {
        id: $id
        label: $label
        featureName: $featureName
        isRead: $isRead
        isCreate: $isCreate
        isUpdate: $isUpdate
        isDelete: $isDelete
        userRoleId: $userRoleId
      }
    ) {
      id
      label
      featureName
      isRead
      isCreate
      isUpdate
      isDelete
      userRoleId
    }
  }
`;

export const UPDATE_USER_ROLE = gql`
  mutation UpdateUserRole($id: String!, $label: String!, $value: String!) {
    updateUserRole(updateUserRoleInput: { id: $id, label: $label, value: $value }) {
      id
      label
      value
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser(
    $id: String!
    $username: String!
    $firstName: String
    $matricule: String!
    $email: String!
    $userRoleId: String!
    $password: String!
  ) {
    updateUser(
      updateUserInput: {
        id: $id
        username: $username
        firstName: $firstName
        matricule: $matricule
        email: $email
        userRoleId: $userRoleId
        password: $password
      }
    ) {
      id
      username
      firstName
      matricule
      email
      userRoleId
      userRole {
        id
        label
      }
      password
      userMatrix {
        id
      }
    }
  }
`;

export const REMOVE_USER = gql`
  mutation RemoveUser($id: String!) {
    removeUser(id: $id) {
      id
      username
    }
  }
`;

export const GET_USER_GUEST_ROLE = gql`
  query GetUserGeustRole {
    getUserGeustRole {
      id
      label
      value
    }
  }
`;

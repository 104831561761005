import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#d34246',
    },
    secondary: {
      main: '#ebb32a',
    },
    success: {
      main: '#0aa836',
    },
    text: {
      primary: '#393939',
      secondary: '#0e0c38',
    },
  },
  typography: {
    fontFamily: 'Poppins, sans-serif',
    h1: {
      fontSize: '5rem',
    },
    h2: {
      fontSize: '2.5rem',
      fontWeight: 600,
      lineHeight: 1.5,
    },
    body1: {
      lineHeight: 1.5,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 1024,
      xl: 1450,
    },
  },
  spacing: 4,
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          "@media (max-width: 1449px)": {
            paddingInline: '1.5rem',
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          boxShadow: 'none',
          lineHeight: 1.5,
          padding: '0.875rem 2rem',
          
        },
        containedSecondary: {
          color: 'white',
        }
      },
    },
    MuiTypography: {
      styleOverrides: {
        body1: {
          marginBottom: '1.5rem',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          marginBottom: 12,
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          color: '#393939',
          borderColor: '#393939',
          borderRadius: 8,
        },
        root: {
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#000842',
          },
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#393939',

          '&.Mui-focused': {
            color: '#0E0C38',
          },
        },
      },
    },
    MuiFormGroup: {
      styleOverrides: {
        root: {
          overflow: "hidden",
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          alignItems: "flex-start",
          backgroundColor: "#f5f3f3",
          borderRadius: 8,
          marginBottom: "1rem",
          marginLeft: "-.75rem",
          padding: "1.2rem .75rem",
        },
        label: {
          marginBottom: 0,
          padding: ".5rem 0 0"
        }
      }
    },
  },
});

export default theme;

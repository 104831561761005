import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  {
    notFound: {
      display: 'grid',
      justifyContent: 'center',
      padding: '5%',
      '& .css-1oushpm-MuiGrid-root': {
        display: 'flex',
        justifyContent: 'center',
        margin: '5%',
      },
      '& a': {
        margin: '5px',
      },
    },
  },
  { name: 'notFound' },
);
export default useStyles;

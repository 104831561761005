export const HOMEPAGE = '/';
export const CONTACT = '/contact';
export const REGISTER = '/register';
export const LOGIN = '/login';
export const QUIZ = '/quiz/:id/:name';
export const QUIZ_USER_GUEST = '/quiz/:idUser/test/:id';
export const QUESTIONNAIRE = '/questionnaire';
export const USERS = '/users';
export const SEANCE = '/seance';
export const DASHBOARD = '/dashboard';
export const PERMISSION = '/permission';
export const RESET_PASSWORD =  '/reset-password';

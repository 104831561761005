import { configureStore, combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { PERSIST, REGISTER, persistReducer } from 'redux-persist';
import countDownReducer from '@/Features/countDown/countDownSlice';
import { LOGIN } from '@/Routes/ConstantRoute';

const persistCongig = {
  key: 'root',
  version: 1,
  storage,
};

const reducer = combineReducers({
  countDown: countDownReducer,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const rootReducer = (state: any, action: any) => {
  if (action?.type === LOGIN) {
    storage.removeItem('persist:root');
    return reducer(undefined, action);
  }
  return reducer(state, action);
};

const persistedReducer = persistReducer(persistCongig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [PERSIST, REGISTER],
      },
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  '@global': {
    '*': {
      'scrollbar-width': "auto",
      "scrollbar-color": "#ebb32a #ffffff",
      "&:hover, &:focus": {
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: "rgba(235, 179, 42, 1)",
          opacity: 1,
        },
      },
    },
    '*::-webkit-scrollbar': {
      width: '.5rem',
      opacity: 0.25,
    },
    '*::-webkit-scrollbar-track': {
      // background: "#ffffff",
      border: "none",
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: "rgba(235, 179, 42)",
      borderRadius: 10,
      opacity: 0.25,
      outline: "none",
      "&:hover, &:focus, &:active": {
        backgroundColor: "rgba(235, 179, 42, 1)",
      },
    },

  },
  app: {
    backgroundColor: "#fbfbfb",
    fontWeight: 400,
  },
}, {name: "app"});

export default useStyles;
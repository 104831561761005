import React, { useState, MouseEvent } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Toolbar, Button, Menu, MenuItem, Typography, Container, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PeopleIcon from '@mui/icons-material/People';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import LogoutIcon from '@mui/icons-material/Logout';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { DASHBOARD, HOMEPAGE, LOGIN, QUESTIONNAIRE, SEANCE, USERS } from '@/Routes/ConstantRoute';
import { useLocalStorage } from '@/Hooks/useLocalStorage';
import { useAppDispatch } from '@/Hooks/useAppStore';
import { useContextHairunQuiz } from '@/Hooks/Context/ContextHairunQuiz';
import profil from '../../../assets/images/profil.png';
import useStyles from './styles';
import { useMobile } from '@/Utils/mediaqueries';
import HeaderForWeb from './HeaderForWeb';

const Header = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { checkedPermission } = useContextHairunQuiz();

  const isMobile = useMobile();

  const { pathname } = useLocation();

  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorElement);
  const [, setToken] = useLocalStorage('token', null);
  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(e.currentTarget);
  };
  const handleClose = (e: MouseEvent<HTMLLIElement>, path: string) => {
    e.preventDefault();
    if (path === LOGIN) {
      dispatch({ type: LOGIN });
      setToken(null);
    }
    setAnchorElement(null);
    navigate(path);
  };

  return (
    <Toolbar className={classes.toolBarWrapper}>
      <Container
        sx={{
          maxWidth: {
            xl: '95%',
            lg: '95%',
          },
        }}
      >
        <Box className={classes.toolBar}>
          <Link to={HOMEPAGE} className={classes.logoHeader}>
            Hairun Quiz
          </Link>

          {isMobile ? (
            <Box className={classes.profileContainer}>
              <Button
                id="menu-button"
                variant="outlined"
                aria-controls={open ? 'menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={e => handleClick(e)}
                endIcon={<ExpandMoreIcon />}
                className={classes.menu}
              >
                <div className={classes.imgContainer}>
                  <img src={profil} alt="profil" className={classes.img} />
                </div>
                <Typography variant="body2" component="span">
                  Administrateur
                </Typography>
              </Button>
              <Menu
                id="menu"
                anchorEl={anchorElement}
                open={open}
                onClose={() => setAnchorElement(null)}
                MenuListProps={{
                  'aria-labelledby': 'menu-button',
                }}
                sx={{
                  zIndex: 'auto',
                }}
              >
                {checkedPermission('User', 'isRead') && (
                  <MenuItem onClick={e => handleClose(e, USERS)}>
                    <PeopleIcon className={classes.icon} /> Gestion utilisateurs
                  </MenuItem>
                )}
                {checkedPermission('Questionnaire', 'isRead') && (
                  <MenuItem onClick={e => handleClose(e, QUESTIONNAIRE)}>
                    <QuestionMarkIcon className={classes.icon} /> Questionnaires
                  </MenuItem>
                )}
                {checkedPermission('Session', 'isRead') && (
                  <MenuItem onClick={e => handleClose(e, SEANCE)}>
                    <AccessTimeFilledIcon className={classes.icon} /> Session
                  </MenuItem>
                )}
                {/* TO DO */}
                {/* <MenuItem onClick={e => handleClose(e, DASHBOARD)}>
                  <DashboardIcon className={classes.icon} /> Dashboard
                </MenuItem> */}
                <MenuItem onClick={e => handleClose(e, LOGIN)}>
                  <LogoutIcon className={classes.icon} /> Déconnexion
                </MenuItem>
              </Menu>
            </Box>
          ) : (
            <HeaderForWeb
              pathname={pathname}
              logout={() => {
                dispatch({ type: LOGIN });
                setToken(null);
              }}
            />
          )}
        </Box>
      </Container>
    </Toolbar>
  );
};

export default Header;

import React, { useEffect, useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AppBar } from '@mui/material';
import { useQuery } from '@apollo/client';
import Header from '@/Components/Layout/Header';
import { useLocalStorage } from '@/Hooks/useLocalStorage';
import { GET_PROFILE } from '@/Services/User/queries';
import { Profile } from '@/Services/User/__generated__/Profile';
import Loading from '@/Components/Feedback/Loading';
import { ContextHairunQuiz } from '@/Hooks/Context/ContextHairunQuiz';

interface IProtected {
  // eslint-disable-next-line no-undef
  children: JSX.Element;
}

const Protected: React.FC<IProtected> = ({ children }) => {
  const [token] = useLocalStorage('token', null);
  const { data, loading, refetch } = useQuery<Profile>(GET_PROFILE);
  const location = useLocation().pathname;

  const hairunQuizContext = useMemo(() => {
    const checkedPermission = (featureNameValue: string, accessKey: string) => {
      const res: any = data?.profile.userRole?.roleAccess?.find(
        (accessRole: any) => accessRole.featureName === featureNameValue,
      );

      return !res ? false : res[accessKey];
    };
    return {
      profile: data,
      checkedPermission,
    };
  }, [data]);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, refetch]);

  if ((!loading && !data) || !token) {
    return <Navigate to="/login" state={location} replace />;
  }

  return (
    <ContextHairunQuiz.Provider value={hairunQuizContext}>
      <AppBar position="sticky">
        <Header />
      </AppBar>
      {loading && <Loading />}
      {!loading && children}
    </ContextHairunQuiz.Provider>
  );
};

export default Protected;
